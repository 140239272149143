import React, { ReactNode, useEffect } from "react";
// import EventBox from "./event/EventBox";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import "./Layout.scss";
import { HelmetProvider } from 'react-helmet-async';
import "./common.scss";
import FixedFooter from "./FixedFooter";
import LoadingWithText from "./others/loading/LoadingWithText";
// import StickySidebar from "sticky-sidebar";
// import { useParams } from "react-router-dom";

interface LayoutProps {
  children: ReactNode;
  showFixedFooter?: boolean;
  isLoading?: boolean;
  avoidCommonDivs?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  showFixedFooter = false,
  isLoading = false,
  avoidCommonDivs = false
}) => {

  // const params = useParams();

  // useEffect(() => {
  //   if(!isLoading) {
  //     console.log('re ini');
  //     const timeoutId = setTimeout(() => {
  //       console.log('after 1 sec');
  //       const sidebar = document.querySelector('#sidebar');
  //       const container = document.querySelector('#main-content');

  //       if (sidebar && container) {
  //         new StickySidebar(sidebar, {
  //           containerSelector: '#main-content',
  //           innerWrapperSelector: '.sidebar__inner',
  //           topSpacing: 100,
  //           bottomSpacing: 20,
  //         });
  //       }
  //     }, 1000); // Delay of 1000 milliseconds (1 second)

  //     // Cleanup function to clear the timeout if the component unmounts before the timeout completes
  //     return () => clearTimeout(timeoutId);
  //   }
  // }, [isLoading, params]);

  return (
    <HelmetProvider>
      <div className="layout">
        <Header />
        {/* <EventBox /> */}
        <main>
          {avoidCommonDivs ? (
            children
          ) : (
            isLoading ? (
              <div className="events_page_loading">
                <LoadingWithText />
              </div>
            ) : (
              <div className="main-div">
                <div className="container main-card"> {/* id="main-content" */}
                  {children}
                  {showFixedFooter && (
                    <FixedFooter />
                  )}
                </div>
              </div>
            )
          )}
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Layout;
