import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resetConferenceAgendaList: false,
};

export const conferenceAgendaSlice = createSlice({
  name: "conferenceAgenda",
  initialState,
  reducers: {
    setResetConferenceAgendaList: (state) => {
      state.resetConferenceAgendaList = !state.resetConferenceAgendaList;
    },
  },
});

export const conferenceAgendaAction = conferenceAgendaSlice.actions;

export const conferenceAgendaReducer = conferenceAgendaSlice.reducer;
