import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resetMeetingsRequestedList: false,
  resetScheduleMeetingsList: false,
  resetMyScheduleList: false,
};

export const meetingSlice = createSlice({
  name: "meetingSlice",
  initialState,
  reducers: {
    setResetMeetingsRequestedList: (state) => {
      state.resetMeetingsRequestedList = !state.resetMeetingsRequestedList;
    },

    setResetScheduleMeetingsList: (state) => {
      state.resetScheduleMeetingsList = !state.resetScheduleMeetingsList;
    },

    setResetMyScheduleList: (state) => {
      state.resetMyScheduleList = !state.resetMyScheduleList;
    },
  },
});

export const meetingAction = meetingSlice.actions;

export const meetingReducer = meetingSlice.reducer;
