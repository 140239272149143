export class CommanEnums {
  static pending = "pending";
  static accepted = "accepted";
  static declined = "declined";
  static rescheduled = "rescheduled";
  static cancelled = "cancelled";

  static users = {
    admin: "admin",
    exhibitor: "exhibitor",
    delegate: "delegate",
    sponsor: "sponsor",
    speaker: "speaker",
    media_partner: "media_partners",
  };

  static userTypes = {
    ADMIN: "admin",
    exhibitor: "exhibitor",
    delegate: "delegate",
    sponsor: "sponsor",
    speaker: "speaker",
    media_partner: "media_partners",

    admin_label: "Admin",
    exhibitor_label: "Exhibitor",
    delegate_label: "Delegate",
    sponsor_label: "Sponsor",
    speaker_label: "Speaker",
    media_partner_label: "Media Partner",
  };

  static redirectUrl = {
    conference_agenda: "conference-agenda",
    one_one_meetings: "1-1-meetings",
  };

  static profile = {
    telephone: "telephone",
  };

  static meetingStatus = {
    pending: "pending",
    accepted: "accepted",
    declined: "declined",
    cancelled: "cancelled",
    rescheduled: "rescheduled",
  };

  static listType = {
    meetings_you_have_requested: "meetings_you_have_requested",
    meetings_requested_by_others: "meetings_requested_by_others",
    my_schedule: "my_schedule",
  }
}
