import "./InfoSideBar.scss";
import { Link, useLocation, useParams } from "react-router-dom";
import { CommanEnums } from "../enums/comman.enums";
import { LocalStorageHelpers } from "../helpers/localstorage.helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Skeleton } from "@mui/material";
import { getImageUrl } from "../userDetails/userDetails";
import StickySidebar from "sticky-sidebar";

const InfoSideBar = () => {
  let params = useParams();
  const eventId = params?.event_id;
  let { eventData, loading }: any = useSelector((state: RootState) => state?.eventData);
  const logout = () => {
    localStorage.clear();
  };

  const navData = [
    {
      img: '/static/images/icons/home.svg',
      title: "Home",
      path: `/event/${eventId}/dashboard`,
    },
    {
      img: '/static/images/icons/information.svg',
      title: "General Information",
      path: `/event/${eventId}/exhibitor-information/general-information`,
      user_type: CommanEnums.users.exhibitor,
    },
    {
      img: '/static/images/icons/faq.svg',
      title: "FAQs",
      path: `/event/${eventId}/exhibitor-information/faqs`,
    },
    {
      img: '/static/images/icons/marketing-graphics.svg',
      title: "Exhibition Information",
      path: `/event/${eventId}/exhibitor-information/exhibition-stand-information`,
      user_type: CommanEnums.users.exhibitor,
    },
    {
      img: '/static/images/icons/additional-orders.svg',
      title: "Additional Orders",
      path: `/event/${eventId}/exhibitor-information/additional-orders`,
      user_type: CommanEnums.users.exhibitor,
    },
    {
      img: '/static/images/icons/shipping-information.svg',
      title: "Shipping Information",
      path: `/event/${eventId}/exhibitor-information/shipping-information`,
      user_type: CommanEnums.users.exhibitor,
    },
    {
      img: '/static/images/icons/exhibitor-insurance.svg',
      title: "Exhibitor Insurance",
      path: `/event/${eventId}/exhibitor-information/exhibitor-insurance`,
      user_type: CommanEnums.users.exhibitor,
    },
    // {
    //   title: "Product Demos",
    //   path: `/event/${eventId}/exhibitor-information/product-demos`,
    //   user_type: CommanEnums.users.exhibitor,
    // },
    {
      img: '/static/images/icons/parking.svg',
      title: "Parking",
      path: `/event/${eventId}/exhibitor-information/parking`,
      user_type: CommanEnums.users.exhibitor,
    },
    {
      img: '/static/images/icons/deadlines.svg',
      title: "Deadlines",
      path: `/event/${eventId}/exhibitor-information/deadlines`,
      user_type: CommanEnums.users.exhibitor,
    },
    // {
    //   title: "Raising Your Profile",
    //   path: `/event/${eventId}/exhibitor-information/raising-your-profile`,
    //   user_type: CommanEnums.users.exhibitor,
    // },
    {
      img: '/static/images/icons/marketing-graphics.svg',
      title: "Marketing Graphics",
      path: `/event/${eventId}/exhibitor-information/marketing-graphics`,
      user_type: CommanEnums.users.exhibitor,
    },
  ];

  const topNavData = [
    {
      img: '/static/images/icons/agenda.svg',
      title: "My Conference Agenda",
      path: `/event/${eventId}/conference-agenda`,
    },
    {
      img: '/static/images/icons/map.svg',
      title: "View Map Of Venue",
      path: `/event/${eventId}/venue-map`,
    },
    {
      img: '/static/images/icons/meetings.svg',
      title: "Meetings",
      path: `/event/${eventId}/1-1-meetings`,
    },
    {
      img: '/static/images/icons/networking.svg',
      title: "Networking Events",
      path: `/event/${eventId}/1-1-meetings/networking-events`,
    },

    {
      img: '/static/images/icons/exhibitors.svg',
      title: "Exhibitors",
      path: `/event/${eventId}/exhibitors`,
    },

    {
      img: '/static/images/icons/hotels.svg',
      title: "Hotels",
      path: `/event/${eventId}/hotels`,
    },
  ];

  const location = useLocation();

  const isActive = (path: string) => {
    if (location.pathname === `/event/${eventId}/1-1-meetings/networking-events`) {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  const renderBottomNavigationLinks = () => {
    let links: any = [];

    const userData = LocalStorageHelpers.getUserData();

    const userType = userData?.user_type;

    navData.forEach((item, index) => {
      if (
        item.user_type === CommanEnums.users.exhibitor &&
        userType != CommanEnums.users.exhibitor
      )
        return;

      links.push(
        <li className="menu-li" key={index}>
          <Link to={item.path} className={`menu-link ${isActive(item.path) ? "active" : ""}`} title={item.title}>
            <span className="menu-link-icon">
              <img src={item.img} alt={item.title} />
            </span>
            <span className="menu-link-name">{item.title}</span>
          </Link>
        </li>
      );
    });

    return links;
  };

  const renderLogo = () => {

    if (!eventData || loading) {
      return (
        <Skeleton
          animation="wave"
          variant="rounded"
          width={210}
          height={150}
          className="header-logo-loader"
        />
      );
    }

    let logo = eventData?.event_logo;

    if (logo) {
      let logoUrl = getImageUrl(logo);

      return (
        <div className="sidebar-logo-div">
          <img src={logoUrl} alt="Eva Event" />
        </div>
      )
    }
    return (
      <div className="sidebar-logo-div">
        <img src={"/static/images/eva-events-logo-linear.png"} alt="Eva Event" />
      </div>
    )
  };

  return (
    <div id="sidebar" className="sidebar-maindiv">
      <div className="sidebar__inner">
        <div className="sidebar-mdiv">
          <div className="sidebar">
            <div className="sidebar-head">
              <div className="left-black-div"></div>
              <div className="sidebar-head-detail">
                {renderLogo()}
                <h2 className="sidebar-logo-title">{eventData?.name}</h2>
              </div>
            </div>
            <div className="sidebar-menu">
              <ul className="menu-ul">
                {renderBottomNavigationLinks()}
                <li className="menu-li">
                  <div className="menu-link">
                    <span className="menu-link-icon"></span>
                    <span className="menu-link-name"><hr /></span>
                  </div>
                </li>
                {topNavData.map((item) => (
                  <li className="menu-li">
                    <Link to={item.path} className={`menu-link ${isActive(item.path) ? "active" : ""}`} title={item.title}>
                      <span className="menu-link-icon">
                        <img src={item.img} alt={item.title} />
                      </span>
                      <span className="menu-link-name">{item.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
              <ul className="menu-ul login-menu-ul">
                <li className="menu-li">
                  <div className="menu-link">
                    <span className="menu-link-icon"></span>
                    <span className="menu-link-name"><hr /></span>
                  </div>
                </li>
                <li className="menu-li mt-auto">
                  <Link to={`/event/${eventId}/helpdesk`} className="menu-link" title="Support">
                    <span className="menu-link-icon">
                      <img src="/static/images/icons/agenda.svg" alt="Support" />
                    </span>
                    <span className="menu-link-name">Support</span>
                  </Link>
                </li>
                <li className="menu-li">
                  <Link to={'/'} onClick={logout} className="menu-link" title="Log Out">
                    <span className="menu-link-icon">
                      <img src="/static/images/icons/log-out.svg" alt="Log Out" />
                    </span>
                    <span className="menu-link-name">Log Out</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSideBar;
