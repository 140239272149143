import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resetnetworkingEventsList: false,
};

export const networkingEventSlice = createSlice({
  name: "networkingEvent",
  initialState,
  reducers: {
    setResetnetworkingEventsList: (state) => {
      state.resetnetworkingEventsList = !state.resetnetworkingEventsList;
    },
  },
});

export const networkingEventAction = networkingEventSlice.actions;

export const networkingEventReducer = networkingEventSlice.reducer;
