import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { DataState } from "./../../components/type/reduxType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { call, put, select, takeLatest } from "redux-saga/effects";
import { EventDataType } from "../../components/type/propsType";
import { EventsApiServices } from "../../utils/api/EventsApiServices";
import ResponseCodes from "../../utils/response-codes";

// export type ProfileDataState = DataState<EventDataType>;

interface initialState {
  loading: boolean;
  eventData: any;
}

const initialState: initialState = {
  loading: false,
  eventData: null,
};

export const eventDataSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    // saga functions

    setEventLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },

    setEvent: (state, action: PayloadAction<any>) => {
      state.eventData = action?.payload;
    },

    fetchEvent: (s, action) => s,

    // setEventLoading: (state) => ({
    //   loaded: false,
    //   status: "loading",
    // }),
  },
});

function* fetchEventData(action: any) {
  // let Params = useParams();
  // const EventId = Params?.event_id;

  try {
    yield put(eventDataSlice.actions.setEventLoading({ loading: true }));
    const response: { data: { data: any; message: any }; response_code: any } =
      yield call(EventsApiServices?.getEventById, action?.payload?.event_id);
    if (response.response_code === ResponseCodes.GET_SUCCESS) {
      // yield put({ type: "event/setEvent", payload: response.data?.data });
      yield put(eventDataSlice.actions.setEvent(response.data?.data));
      yield put(eventDataSlice.actions.setEventLoading({ loading: false }));
    } else {
      // yield put(eventDataSlice?.actions.setEvent(response.data?.data));
      toast.error("Please select event");
    }

    // yield put({
    //   type: "profile/errorLoadingProfile",
    //   payload: response.data.message,
    // });
  } catch (e) {
    yield put({ type: "profile/errorLoadingProfile", payload: e });
  }
}

export const eventDataAction = eventDataSlice.actions;
export const eventDataReducer = eventDataSlice.reducer;

export function* eventSagas() {
  yield takeLatest("event/fetchEvent", fetchEventData);
}
