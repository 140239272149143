import { toast } from "react-hot-toast";
import { ProfileApiServices } from "./../../../utils/api/ProfileApiServices";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import ResponseCodes from "../../../utils/response-codes";

const initialState = {
  delegateProfileModal: false,
  meetingRequestModal: false,
  messageModal: false,
  rescheduleMeetingModalId: "",
  cancelMeetingModalId: "",
  declineMeetingModalId: "",
  meetingDetails: {} as any,
  loading: false,
  userData: null,
};

export const usersListSlice = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    setDelegateProfileModal: (state) => {
      state.delegateProfileModal = !state.delegateProfileModal;
    },
    setMeetingRequestModal: (state) => {
      state.meetingRequestModal = !state.meetingRequestModal;
    },
    setMessageModal: (state) => {
      state.messageModal = !state.messageModal;
    },
    setRescheduleMeetingModal: (state, action) => {
      state.rescheduleMeetingModalId = action?.payload?.id;
    },
    setCancelMeetingModal: (state, action) => {
      state.cancelMeetingModalId = action?.payload?.id;
      state.meetingDetails = action?.payload?.meetingData || {};
    },
    setDeclineMeetingModal: (state, action) => {
      state.declineMeetingModalId = action?.payload?.id;
      state.meetingDetails = action?.payload?.meetingData || {};
    },
    fetchUser: (s) => {
      return s;
    },

    setUser: (state, action: PayloadAction<any>) => {
      state.userData = action?.payload;
    },

    setUserLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
  },
});

function* fetchUserData(action: any) {
  try {
    yield put(usersListSlice.actions.setUserLoading({ loading: true }));
    const response: { data: { data: any; message: any }; response_code: any } =
      yield call(ProfileApiServices?.getProfile);
    if (response.response_code === ResponseCodes.GET_SUCCESS) {
      // yield put({ type: "event/setEvent", payload: response.data?.data });
      yield put(usersListSlice.actions.setUser(response.data?.data));
      yield put(usersListSlice.actions.setUserLoading({ loading: false }));
    }

    // yield put({
    //   type: "profile/errorLoadingProfile",
    //   payload: response.data.message,
    // });
    else toast.error(response?.data?.message || "Internal server error!");
  } catch (e) {
    yield put({ type: "profile/errorLoadingProfile", payload: e });
  }
}

export const usersListAction = usersListSlice.actions;
export const usersListReducer = usersListSlice.reducer;

export function* userProfileSagas() {
  yield takeLatest("usersList/fetchUser", fetchUserData);
}
