import React from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <footer className="footer_main_container">
        
        
        <div className="footer_container">
          {/* <div className="footer-menu">
            <ul className="footer-menu-ul">
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="How to register">
                  <MdKeyboardArrowRight className="arrow_right" />
                  How to register
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="How it works">
                  <MdKeyboardArrowRight className="arrow_right" />
                  How it works
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="Become a Sponsor">
                  <MdKeyboardArrowRight className="arrow_right" />
                  Become a Sponsor
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="Exhibit with us">
                  <MdKeyboardArrowRight className="arrow_right" />
                  Exhibit with us
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="Cookie Policy">
                  <MdKeyboardArrowRight className="arrow_right" />
                  Cookie Policy
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="Privacy Policy">
                  <MdKeyboardArrowRight className="arrow_right" />
                  Privacy Policy
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="Terms & Conditions">
                  <MdKeyboardArrowRight className="arrow_right" />
                  Terms & Conditions
                </Link>
              </li>
              <li className="footer-menu-li">
                <Link to={'#'} className="footer-menu-link" title="Contact us">
                  <MdKeyboardArrowRight className="arrow_right" />
                  Contact us
                </Link>
              </li>
            </ul>
          </div> */}
        	<p style={{textAlign: "center"}} className="copyright-p">Copyright &copy; 2024 EVA International Ltd t/a EVA Events</p>
          <hr className="footer-hr"/>
          <>
            { /*
            <div className="footer_row">
              <div className="footer_part">
                <div className="footer_header">
                  <p>Events</p>
                </div>
                <div className="sub_text">
                  <ul>
                    <li>- ACH Christmas 2024 Paris</li>
                    <li>- GSE Istanbul 2024 Gala</li>
                  </ul>
                </div>
              </div>
              <div className="footer_part">
                <div className="footer_header">
                  <p>Resources</p>
                </div>
                <div className="sub_text_resources">
                  <ul>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      How to register
                    </li>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      How it works
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Become a Sponsor
                    </li>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Exhibit with us
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_part">
                <div className="footer_header">
                  <p>Information</p>
                </div>
                <div className="sub_text_resources">
                  <ul>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Cookie Policy
                    </li>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Privacy Policy
                    </li>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Terms & Conditions
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Cookie Policy
                    </li>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Privacy Policy
                    </li>
                    <li>
                      <MdKeyboardArrowRight className="arrow_right" />
                      Terms & Conditions
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_part">
                <div className="footer_header">
                  <p>Contact</p>
                </div>
                <div className="sub_text">
                  <ul>
                    <li>support@evacrm.com</li>
                    <li>01424 232 4312</li>
                  </ul>
                </div>
              </div>
            </div>
            */ }
          </>
          <div className="footer_bottom">
            <p>
            EVA Events is a subsidiary of Eva International Media Ltd. Based in 
            England and Wales. Register Company No. 2323023. 
            For more information, visit www.evaintmedia.com
            </p>
          </div>
        </div>
        
      </footer>
      
    </>
  );
};

export default Footer;
