// import moreQuationPaymentSlice from "./moreQuationPaymentSlice"

import { all } from "redux-saga/effects";
import { conferenceAgendaReducer } from "./conferenceAgendaSlice";
import { eventDataReducer, eventSagas } from "./eventDataSlice";
import { meetingReducer } from "./meetingSlice";
import { networkingEventReducer } from "./networkingEventSlice";
import { passwordReducer } from "./passwordSlice";
import { profileEditCheckBoxReducer } from "./profileEditCheckBoxSlice";
import { delegateProfileEditReducer } from "./users/delegateProfileEditSlice";
import { userProfileSagas, usersListReducer } from "./users/usersListSlice";
import { notificationReducer } from "./NotificationSlice";

export const rootReducer = {
  profileEditCheckBox: profileEditCheckBoxReducer,
  delegateProfileEdit: delegateProfileEditReducer,
  conferenceAgenda: conferenceAgendaReducer,
  eventData: eventDataReducer,
  meeting: meetingReducer,
  usersList: usersListReducer,
  notification: notificationReducer,
  networkingEvent: networkingEventReducer,
  password: passwordReducer,
};

export function* rootSaga() {
  yield all([eventSagas(), userProfileSagas()]);
}
