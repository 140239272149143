import {
  ISetEditOption,
  ISetlinkedInProfile,
} from "../../components/type/reduxType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateType {
  profile: boolean;
  profileSurveyOptions: any[];
  linkedIn: string;
  websiteIn: string;
  mobileNumber: string;
  mobileCode: string;
  contactInformation: {
    linkedIn: string;
    websiteIn: string;
    mobileNumber: string;
    mobileCode: string;
  };
}

const initialState: initialStateType = {
  profile: false,
  profileSurveyOptions: [],
  linkedIn: "",
  websiteIn: "",
  mobileNumber: "",
  mobileCode: "",
  contactInformation: {
    linkedIn: "",
    websiteIn: "",
    mobileNumber: "",
    mobileCode: "",
  },
};

export const profileEditCheckBoxSlice = createSlice({
  name: "profileEditCheckBox",
  initialState,
  reducers: {
    setProfile: (state) => {
      state.profile = !state.profile;
    },

    setLinkedIn: (state, action: PayloadAction<ISetlinkedInProfile>) => {
      state.linkedIn = action?.payload?.data;
    },

    setWebsiteIn: (state, action: PayloadAction<ISetlinkedInProfile>) => {
      state.websiteIn = action?.payload?.data;
    },

    setContactInformation: (
      state,
      action: PayloadAction<ISetlinkedInProfile>
    ) => {
      state.websiteIn = action?.payload?.data;
    },

    setSelectedExpert: (state, action: PayloadAction<ISetEditOption>) => {
      let options: any[] = [];

      if (action?.payload === null) {
        state.profileSurveyOptions = [];
      } else {
        const result = state?.profileSurveyOptions.filter(
          (data) => data?.option_id === action?.payload?.option_id
        );

        if (result?.length === 0) {
          if (state.profileSurveyOptions.length > 0) {
            options = [...state.profileSurveyOptions, action?.payload];
          } else {
            options = [action?.payload];
          }
        } else {
          const resultFilter = state?.profileSurveyOptions.filter(
            (data) => data?.option_id !== action?.payload?.option_id
          );
          options = resultFilter;
        }
        state.profileSurveyOptions = options;
      }
    },
  },
});

export const profileEditCheckBoxAction = profileEditCheckBoxSlice.actions;

export const profileEditCheckBoxReducer = profileEditCheckBoxSlice.reducer;
