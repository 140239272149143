import {
  ISetlinkedInProfile,
  ISetStringData,
} from "./../../../components/type/reduxType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface initialStateType {
  contactInformation: {
    linkedIn: string;
    websiteIn: string;
    mobileNumber: string;
    mobileCode: string;
  };
}

const initialState = {
  personalInfo: {
    description: "",
    job_title: "",
    company: {
      name: "",
      value: "",
    },

    category: {
      name: "",
      value: "",
    },
  },

  personalInfoName: {
    first_name: "",
    last_name: "",
    user_name: "",
  },

  contactInformation: {
    linkedIn: "",
    website: "",
    phone: {
      mobileNumber: "",
      mobileCode: "",
    },
    telephone: "",
  },

  administratorInfo: {
    pa_name: "",
    pa_email: "",
  },
  saveBtnToggle: false,
  resetprofileData: false,
  resetprofile: false,
};

export const delegateProfileEditSlice = createSlice({
  name: "profileEditCheckBox",
  initialState,
  reducers: {
    setContactInformation: (state, action) => {
      state.contactInformation = action?.payload;
    },

    setLinkedIn: (state, action: PayloadAction<ISetStringData>) => {
      state.contactInformation.linkedIn = action?.payload.data;
    },
    setWebsite: (state, action: PayloadAction<ISetStringData>) => {
      state.contactInformation.website = action?.payload.data;
    },
    setPhone: (state, action) => {
      state.contactInformation.phone = action?.payload;
    },

    setTelephoneValue: (state, action) => {
      state.contactInformation.telephone = action?.payload.data;
    },

    setAdministratorInfo: (state, action) => {
      state.administratorInfo = action?.payload;
    },
    setPaName: (state, action: PayloadAction<ISetStringData>) => {
      state.administratorInfo.pa_name = action?.payload.data;
    },
    setPaEmail: (state, action: PayloadAction<ISetStringData>) => {
      state.administratorInfo.pa_email = action?.payload.data;
    },

    setSaveBtn: (state) => {
      state.saveBtnToggle = true;
    },

    setSaveBtnToggle: (state) => {
      state.saveBtnToggle = !state?.saveBtnToggle;
    },

    setResetprofileData: (state) => {
      state.resetprofileData = !state?.resetprofileData;
    },

    setPersonalInfo: (state, action) => {
      state.personalInfo = action?.payload;
    },

    setDescription: (state, action: PayloadAction<ISetStringData>) => {
      state.personalInfo.description = action?.payload?.data;
    },

    seCompany: (state, action) => {
      state.personalInfo.company = action?.payload;
    },

    seCategory: (state, action) => {
      state.personalInfo.category = action?.payload;
    },

    setJobTitle: (state, action: PayloadAction<ISetStringData>) => {
      state.personalInfo.job_title = action?.payload?.data;
    },

    setFirstName: (state, action: PayloadAction<ISetStringData>) => {
      state.personalInfoName.first_name = action?.payload?.data;
    },

    setLastName: (state, action: PayloadAction<ISetStringData>) => {
      state.personalInfoName.last_name = action?.payload?.data;
    },

    setUserName: (state, action: PayloadAction<ISetStringData>) => {
      state.personalInfoName.user_name = action?.payload?.data;
    },

    setResetprofile: (state) => {
      state.resetprofile = !state?.resetprofile;
    },
  },
});

export const delegateProfileEditAction = delegateProfileEditSlice.actions;

export const delegateProfileEditReducer = delegateProfileEditSlice.reducer;
