import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface NotificationState {
    count: number;
    meetingCounts: { [key: string]: number };
}

const initialState: NotificationState = {
    count: 0,
    meetingCounts: {},
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotificationCounts: (
            state,
            action: PayloadAction<{ meetingCounts: { [key: string]: number } }>
        ) => {
            state.meetingCounts = action.payload.meetingCounts;
            state.count = Object.values(action.payload.meetingCounts).reduce((sum, current) => sum + current, 0);
        },
        incrementNotificationCount: (state, action: PayloadAction<{ meetingId: string }>) => {
            const meetingId = action.payload.meetingId;
            if (state.meetingCounts[meetingId]) {
                state.meetingCounts[meetingId] += 1;
            } else {
                state.meetingCounts[meetingId] = 1;
            }
            state.count += 1;
        },
        clearNotificationCount: (state, action: PayloadAction<{ meetingId: string }>) => {
            const meetingId = action.payload.meetingId;
            if (state.meetingCounts[meetingId]) {
                state.count -= state.meetingCounts[meetingId];
                delete state.meetingCounts[meetingId];
            }
        },
    },
});

export const { setNotificationCounts, incrementNotificationCount, clearNotificationCount } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
