import axiosInstance from "../Axios";

export class CommonApi {
  static getExhibitors = async (event_id: string) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/events/${event_id}/exhibitors`,
        params: {
          page: 1,
          limit: 1000,
          search: "",
          created_at: "desc",
        },
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };

  static getHotels = async (event_id: string) => {
    try {
      const res = await axiosInstance({
        method: "get",
        url: `web/events/${event_id}/hotels`,
        params: {
          page: 1,
          limit: 1000,
          search: "",
          created_at: "desc",
        },
      });
      return {
        data: res.data,
        response_code: res?.data?.response_code,
      };
    } catch (err: any) {
      return {
        error: "Something went wrong !",
        data: err?.response,
      };
    }
  };
}
