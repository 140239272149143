import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotificationCounts } from '../../redux/features/NotificationSlice';
import { ProfileApiServices } from '../../utils/api/ProfileApiServices';

const NotificationPoller = () => {
    const dispatch = useDispatch();
    const [pollingInterval, setPollingInterval] = useState<NodeJS.Timer | null>(null);

    const fetchNotifications = async () => {
        try {
            const res = await ProfileApiServices.getNotifications();
            if (res.response_code === "SUCCESS") {
                const allNotifications = res.data;

                const unreadNotifications = allNotifications.filter(
                    (notification: any) => !notification.read && notification.meeting_id !== null
                );


                const meetingCounts: { [key: string]: number } = unreadNotifications.reduce(
                    (acc: { [key: string]: number }, notification: any) => {
                        const meetingId = notification?.meeting_id?._id;
                        if (acc[meetingId]) {
                            acc[meetingId] += 1;
                        } else {
                            acc[meetingId] = 1;
                        }
                        return acc;
                    },
                    {}
                );

                dispatch(setNotificationCounts({ meetingCounts }));
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };


    useEffect(() => {
        fetchNotifications();

        const interval = setInterval(fetchNotifications, 30000);
        setPollingInterval(interval);

        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [dispatch]);

    return null;
};

export default NotificationPoller;
